// src/components/Services.js
import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const Services = () => (
  <section id="services">
    <div id="consultancy">
      <div class="container pb-5">
        <div class="row align-items-center justify-content-center">
          <div
            class="col-md-9 col-lg-6 order-1 order-lg-0 text-center"
            data-aos="fade-left"
          >
            <ScrollAnimation
              animateIn="bounceInLeft"
              animateOut="bounceOutLeft"
              duration="1"
              initiallyVisible={true}
            >
              <img
                class="content-skewed-item img-fluid shadow-lg rounded-lg"
                src="img/6048.jpg"
              />
            </ScrollAnimation>
          </div>

          <div
            class="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0"
            data-aos="fadeInLefts"
          >
            <ScrollAnimation
              animateIn="fadeIn"
              animateOut="fadeOut"
              duration="1"
              initiallyVisible={true}
            >
              <div class="mb-5">
                <h2>Consultancy</h2>
                <p class="lead">
                  Extending tens of years of experience to your team providing
                  guidance on how to adopt devops cababilities.
                </p>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <h6>Assesment</h6>
                  <p class="mb-2 small">
                    Starting the discussion by understanding where current
                    systems stands. Offering a free of charge assessment session
                    to baseline current systems
                  </p>
                </div>
                <div class="col-md-6 mb-4">
                  <h6>Design your automoation pipelines</h6>
                  <p class="mb-2 small mb-0">
                    A good extensible and unified design for automation
                    cababilities is crutial for devops success. we offer our
                    services helping your teams to atounimously deliver value to
                    your customers
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <h6>Implementation</h6>
                  <p class="small mb-0">
                    We galdely extend our services to implementing the different
                    devops cababilities based on the requirements and feedback
                    given by your team{" "}
                  </p>
                </div>
                <div class="col-md-6 mb-4"></div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
    <div id="training">
      <div class="container">
        <div class="row align-items-center justify-content-center pt-5">
          <div
            class="col-md-9 col-lg-6 order-0 order-lg-0"
            data-aos="fade-right"
          >
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            duration="1"
          >
            <div class="mb-5">
              <h2>Training and enablement</h2>
              <p class="lead">
                The true power of devops is when everyone in the team is talking
                and acting the devops way. For this to happen, we are glade to
                extend our training services for development and operation teams
                to truly enable the devops culuture .
              </p>
            </div>
            <div class="row">
              <div class="col-md-6 mb-4">
                <h6>Cababilities training</h6>
                <p class="mb-2 small">
                  Offering technical trainings for the different cababilities
                  including CICD, IAC, observability and containerization.
                </p>
              </div>
              <div class="col-md-6 mb-4">
                <h6>DevOps concepts and culuture</h6>
                <p class="mb-2 small mb-0">
                  We also offer orientation sessions for management and non
                  technical staff to help them get better understanding of the
                  value behind devops and to embrase the proper devops
                  sponsership by leadership teams.
                </p>
              </div>
            </div>
          </ScrollAnimation>
          </div>

          <div
            class="col-lg-6 order-1 order-lg-1 mb-5 mb-lg-0 text-center"
            data-aos="fade-right"
          >
          <ScrollAnimation
            animateIn="bounceInRight"
            animateOut="bounceOutRight"
            duration="1"
          >
              <img
                class="content-skewed-item img-fluid shadow-lg rounded-lg"
                src="img/5187672.jpg"
              />
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
    <div id="interviews">
      <div class="container pb-5">
        <div class="row align-items-center justify-content-center">
          <div
            class="col-md-9 col-lg-6 order-1 order-lg-0 text-center"
            data-aos="fade-left"
          >
          <ScrollAnimation
            animateIn="bounceInLeft"
            animateOut="bounceOutLeft"
            duration="1"
          >
            <img
              class="content-skewed-item img-fluid shadow-lg rounded-lg"
              src="img/3545798.jpg"
            />
            </ScrollAnimation>
          </div>
          <div
            class="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0"
            data-aos="fade-left"
          >
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            duration="1"
          >
            <div class="mb-5">
              <h2>Technical Assessments</h2>
              <p class="lead">
                Struggling getting the right devops calibars onboard, we extend
                our devops technical assessments services ..
              </p>
            </div>
            <div class="row">
              <div class="col-md-6 mb-4">
                <h6>Technical interviews</h6>
                <p class="mb-2 small">
                  Starting the discussion by understanding where current systems
                  stands. Offering a free of charge assessment session to
                  baseline current systems
                </p>
              </div>
              <div class="col-md-6 mb-4">
                <h6>Technical assignments</h6>
                <p class="small mb-0">
                  We galdely extend our services to implementing the different
                  devops cababilities based on the requirements and feedback
                  given by your team{" "}
                </p>
              </div>
              <div class="col-md-6 mb-4"></div>
            </div>
            </ScrollAnimation>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Services;
