// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link as RouterLink, Navigate } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

import './App.css';

import Services from './components/Services';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';
import ChatPopup from './components/ChatPopup';
import Header from './components/Header';
import NavBar from './components/NavBar';

const App = () => {

  const TITLE = 'Alraqamy';
  
  return (
    
    <>
    
    <Router>
    <Helmet>
    <title>{ TITLE }</title>
  </Helmet>
      

      <div id="layoutDefault" className="app">
          <NavBar/>
          <Header/>
          <Services/>
          <AboutUs/>
          <ChatPopup/>
          <Contact/>
        <Footer />
      </div>
        </Router>
    
    </>
  );
};

export default App;
