// src/components/Contact.js
import React from 'react';
import { Link } from 'react-router-dom';

const Contact = () => (
  
  <section class="bg-light py-10">
  <div class="container" id="contact">
      <div class="row justify-content-center align-items-center">
          <div class="col-lg-6   text-center">
              <div class="badge badge-marketing badge-pill badge-secondary-soft text-secondary mb-3">Start today!</div>
              <h2 class="page-header-title">Give your business a digital boost</h2>
              <p class="page-header-text">Send a request for proposal according to your business case (RFP).</p>
              <div class="section-preheading">Call Anytime<br /><a href="#!">(+971) 56-2136832</a><br /><br /></div>
              <div class="section-preheading">Email Us<br /><a href="#!">hello@alraqamy.com</a><br /></div>
          </div>
      </div>
  </div>
</section>
);

export default Contact;
