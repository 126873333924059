import React,  { useState, useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';





const NavBar = () => {
  
  const [showMenu, setShowMenu] = useState(true); // Initial state for the menu visibility

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    // Function to update showMenu based on screen width
    const updateShowMenu = () => {
      if (window.innerWidth < 768) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    };

    // Call the function initially
    updateShowMenu();

    // Event listener to update showMenu on window resize
    window.addEventListener('resize', updateShowMenu);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateShowMenu);
    };
  }, []); // Empty dependency array to run the effect only once on mount


  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
<nav className="navbar">    
<div className="logo-image inline-block float-left" >
    <button class="navbar-toggler collapsed float-right" type="button" onClick={toggleMenu}
    data-toggle="collapse" data-target="#navbarSupportedContent" 
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
      </div>  { showMenu  && (
    <ul>
      <li>
        <ScrollLink to="header" smooth={true} duration={500}>
          Home
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="services" smooth={true} duration={500}>
          Services
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="about-us" smooth={true} duration={500}>
          About Us
        </ScrollLink>
      </li>
      <li>
        <ScrollLink to="contact" smooth={true} duration={500}>
          Contact
        </ScrollLink>
      </li>
    </ul>
  )}
</nav>
  );
}


export default NavBar;