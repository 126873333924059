import React from "react";

const Footer = () => (
  <footer class="footer pt-10 pb-5 mt-auto bg-dark footer-dark  ">
    <div class="row align-items-center justify-content-center">
      <div
        class="col-md-9 col-lg-6 order-1 order-lg-0 text-center container"
        data-aos="fade-left"
      ></div>
      <div
        class="col-lg-2 order-0 order-lg-1 mb-5 mb-lg-0"
      >
        <div class="mb-5">
          <ul>
            <h4>SERVICES</h4>
            <li>
              <a href="#consultancys">Consulting</a>
            </li>
            <li>
              <a href="#training">Training</a>
            </li>
            <li>
              <a href="#interviews">Interviews</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="col-lg-2 order-0 order-lg-1 mb-5 mb-lg-0"
      >
        <div class="mb-5">
          <ul>
            <h4>LINKS</h4>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a href="#">About Us</a>
            </li>
            <li>
              <a href="#">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>

    </div>
    
    <div class="copyright col-md-12">
        <img class="img-fluid logodark" src="img/logodark.png" />
      </div>
    <div class="copyright col-md-12">
        <h5>Copyright © alraqamy.com 2024</h5>
      </div>
  </footer>
);

export default Footer;
