// ChatPopup.js
import React, { useState } from 'react';

const ChatPopup = () => {
  const [isFormVisible, setIsFormVisible] = useState(false);

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
  };

  const closeForm = () => {
    setIsFormVisible(false);
  };

  return (
    <div className={`chat-popup-container ${isFormVisible ? 'popup-open' : ''}`}>
      <div className="chat-popup-icon" onClick={toggleFormVisibility}>
        
      </div>
      {isFormVisible && (
        <div className="chat-popup-form">
          <div className="close-icon" onClick={closeForm}>
            &times;
          </div>
          <form>
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" />

            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" />

            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message"></textarea>

            <button type="submit">Send</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatPopup;
