import React from "react";
import { TypeAnimation } from 'react-type-animation';

const SliderBanner = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    arrows: true,
    adaptiveHeight: true,
  };

  return (
    <div id="header" class="container pb-5" className="header align-middle">
<h1>
<TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'Go Digital',
        2000, // wait 1s before replacing "Mice" with "Hamsters"
        'Grow your Business',
        2000,
        'Start Now!',
        5000
      ]}
      wrapper="span"
      speed={50}
      style={{ display: 'inline-block' }}
      repeat={Infinity}
    /></h1>
    </div>
  );
};

export default SliderBanner;
