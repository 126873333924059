// src/components/AboutUs.js
import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from "react-animate-on-scroll";

const AboutUs = () => (
    <section id="about-us" class="bg-dark py-10" >

  <div  className="content container">
    
  <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            duration="1"
          >
        <div class="row justify-content-center text-center">
            <div class="col-lg-8" data-aos="fade-in">
                <h2 class="text-white">At alraqamy.com, We look forward to a truely digitalized world!</h2>
                <p class="lead text-white-50 mb-5">Every business should benifit from the digital revolution. From big firms, to small and even tiny businesses. If tech is making our life easier, we should leaverage it to everyone on the planet.</p>
            </div>
        </div>
        <div class="row my-5">
            <div class="col-lg-6 mb-5">
                <div class="d-flex h-100" data-aos="fade-right">
                    <div class="icon-stack flex-shrink-0 bg-teal text-white"><i data-feather="smile"></i></div>
                    <div class="ml-4">
                        <h5 class="text-white">Value to Customer</h5>
                        <p class="text-white-50">We don't measure our success based on our financials. Instead, we do based on growth of our customers.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-5">
                <div class="d-flex h-100" data-aos="fade-left">
                    <div class="icon-stack flex-shrink-0 bg-teal text-white"><i data-feather="feather"></i></div>
                    <div class="ml-4">
                        <h5 class="text-white">State of the art.</h5>
                        <p class="text-white-50">At alraqamy.com, we strongly believe in innovation. We don't write software, develop software, we craft it.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mb-5 mb-lg-0">
                <div class="d-flex h-100" data-aos="fade-right">
                    <div class="icon-stack flex-shrink-0 bg-teal text-white"><i data-feather="key"></i></div>
                    <div class="ml-4">
                        <h5 class="text-white">Agility is key</h5>
                        <p class="text-white-50">Businesses changes fast. and so do our software. We put Agility in the heart of our operations. We will always be agile.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="d-flex h-100" data-aos="fade-left">
                    <div class="icon-stack flex-shrink-0 bg-teal text-white"><i data-feather="repeat"></i></div>
                    <div class="ml-4">
                        <h5 class="text-white">Evolution</h5>
                        <p class="text-white-50">We belive in evolution as the act of continual improvement. We shoot for the best and keep improving.</p>
                    </div>
                </div>
            </div>
    </div>  
    </ScrollAnimation>
    </div>

    </section>
);

export default AboutUs;
